<template>
  <div>
    <div class="hxcp"></div>
    <!-- 内容一 -->
    <div class="content1 container">
      <show :styles="{ top: '4em', left: '-10em' }" :moveData="{ left: '6em' }">
        <p class="title">构建虚实结合、融合共生的数字孪生应用</p>
      </show>
      <show :styles="{ top: '9.4375em', left: '-10em' }" :moveData="{ left: '6em' }">
        <img src="../../assets/images/bg_img/LinkView2.png" alt="" />
      </show>
      <show :styles="{ top: '12em', left: '30em' }" :moveData="{ left: '44.625em' }">
        <div class="infos">
          <p class="title1">数字孪生与可视化平台</p>
          <p class="title2">LinkView</p>
          <p class="border"></p>
          <p class="text">
            数字孪生与可视化平台基于物联网、BIM、GIS等技术，在网络数字空间，
          </p>
          <p class="text">
            再造一个与现实世界匹配对应的数字世界，为构建现实与虚拟一一对应、协
          </p>
          <p class="text">同交互、智能操控的复杂系统提供技术基础。</p>
        </div>
      </show>
    </div>
    <!-- 内容二 -->
    <div class="content2">
      <div class="container">
        <p class="title">产品特点</p>
        <show :styles="{ left: '20em', top: '11.5em' }" :moveData="{ left: '11.5em' }">
          <div class="n"></div>
        </show>
        <show :styles="{ left: '20em', top: '12.3em' }" :moveData="{ left: '12.5em' }">
          <p class="no">01</p>
        </show>
        <show :styles="{ left: '60em', top: '11.5em' }" :moveData="{ left: '47.5em' }">
          <div class="n"></div
        ></show>
        <show :styles="{ left: '60em', top: '12.5em' }" :moveData="{ left: '48.5em' }">
          <p class="no">02</p>
        </show>
        <show :styles="{ left: '20em', top: '18.5em' }" :moveData="{ left: '11.5em' }">
          <div class="n"></div
        ></show>
        <show :styles="{ left: '20em', top: '19.5em' }" :moveData="{ left: '12.5em' }">
          <p class="no">03</p>
        </show>
        <show :styles="{ left: '60em', top: '18.5em' }" :moveData="{ left: '47.5em' }">
          <div class="n"></div>
        </show>
        <show :styles="{ left: '60em', top: '19.5em' }" :moveData="{ left: '48.5em' }">
          <p class="no">04</p>
        </show>
        <show :styles="{ left: '60em', top: '12.5em' }" :moveData="{ left: '16em' }">
          <div class="text text_1">
            <p>提供强大的2D、3D、图表、空间</p>
            <p>可视化展示能力</p>
          </div>
        </show>
        <show :styles="{ left: '60em', top: '12.5em' }" :moveData="{ left: '52.5em' }">
          <div class="text text_2">
            <p>支持对设备、场站、建筑、城市等</p>
            <p>多场景的三维实时渲染呈现</p>
          </div>
        </show>
        <show :styles="{ left: '60em', top: '19.5em' }" :moveData="{ left: '16em' }">
          <div class="text text_3">
            <p>提供从大屏端、桌面端、网页端、</p>
            <p>移动端等多终端一体化展示</p>
          </div>
        </show>
        <show :styles="{ left: '60em', top: '19.5em' }" :moveData="{ left: '52.5em' }">
          <div class="text text_4">
            <p>基于WebGL三维引擎，兼容IE</p>
            <p>Edge、Chrome等主流浏览器</p>
          </div>
        </show>
        <show :styles="{ left: '60em', top: '17em' }" :moveData="{ left: '11.9em' }">
          <p class="border b1"></p>
        </show>
        <show :styles="{ left: '60em', top: '17em' }" :moveData="{ left: '46em' }">
          <p class="border b2"></p>
        </show>
        <show :styles="{ left: '50em', top: '11em' }" :moveData="{ left: '32.2em' }">
          <img src="../../assets/images/bg_img/special.png" alt="" />
        </show>
      </div>
    </div>
    <!-- 内容三 -->
    <div class="content3 container">
      <div class="title">
        <p class="title1">应用场景</p>
        <p class="title2">Application Scenario</p>
      </div>
      <ul>
        <li
          @mouseenter="e.show = 1"
          @mouseleave="e.show = 2"
          @click="e.show != 1 ? (e.show = 1) : (e.show = 2)"
          v-for="(e, i) in imgs"
          :key="i"
        >
          <img :src="e.src" alt="" />
          <p
            class="text1"
            :class="e.show == 1 ? 'top1_show' : e.show == 2 ? 'top1_none' : ''"
          >
            {{ e.text1 }}
          </p>
          <p
            class="text2"
            :class="e.show == 1 ? 'top2_show' : e.show == 2 ? 'top2_none' : ''"
          >
            {{ e.text2 }}
          </p>
          <p
            class="bg_color"
            :class="e.show == 1 ? 'bgColor_show' : e.show == 2 ? 'bgColor_none' : ''"
          ></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: "",
      imgs: [
        {
          src: require("../../assets/images/bg_img/LinkView3.png"),
          text1: "数字孪生应用",
          text2: "构建虚实结合的数字孪生应用",
          show: false,
        },
        {
          src: require("../../assets/images/bg_img/LinkView4.png"),
          text1: "城市三维底座",
          text2: "基于GIS+BIM的城市数字三维底座",
          show: false,
        },
        {
          src: require("../../assets/images/bg_img/LinkView5.png"),
          text1: "管理驾驶舱",
          text2: "数据的多维展示与可视化分析",
          show: false,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.content1 {
  height: 42.75em;
  .title {
    width: 18em;
    font-size: 1.5em;
    font-weight: bold;
  }
  img {
    width: 27.125em;
  }

  .title1 {
    color: rgb(0, 112, 215);
    font-size: 1.9em;
  }
  .title2 {
    color: rgb(0, 112, 215);
    font-size: 2.75em;
    font-weight: bold;
  }
  .border {
    width: 5.375em;
    height: 4px;
    background-color: #626462;
    margin: 1.5em 0;
  }
  .text {
    width: 33em;
    font-size: 0.875em;
    color: #4b4b4b;
    margin-bottom: 0.5em;
  }
}
.content2 {
  height: 30.8125em;
  background: url("../../assets/images/bg_img/hf(9).jpg") no-repeat center transparent;
  .title {
    position: absolute;
    top: 2em;
    left: 18em;
    font-size: 1.9em;
    font-weight: 500;
    color: #fff;
  }

  .text {
    color: #dedede;
    font-size: 0.9em;
    width: 16em;
  }
  .n {
    width: 4em;
    height: 4em;
    border-radius: 100%;
    background-color: #2086ea;
  }
  .no {
    font-size: 1.9em;
    color: #fff;
  }

  .border {
    width: 19.5em;
    height: 1px;
    background-color: #788b97;
  }
}

.content3 {
  height: 25em;
  .title {
    position: absolute;
    top: 3em;
    left: 33em;
  }
  .title1 {
    font-size: 2.2em;
    color: #353535;
  }
  .title2 {
    margin-top: 0.4em;
    font-size: 0.9em;
    color: #666666;
  }
  ul {
    position: absolute;
    top: 9em;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    li {
      width: 20.8em;
      height: 10.2em;
      overflow: hidden;
      img {
        width: 20.8em;
      }
    }
  }
  .text1 {
    width: 17em;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 4em;
    color: #fff;
    font-size: 1.2em;
    z-index: 10;
  }
  .text2 {
    width: 20.8em;
    opacity: 0;
    position: absolute;
    text-align: center;
    color: #cccccc;
    top: 6.5em;
    z-index: 10;
  }
  .bg_color {
    width: 20.8em;
    height: 10em;
    position: absolute;
    top: 0;
    overflow: hidden;
  }
}

.top1_show {
  animation: text_top1_show 0.5s ease forwards;
}
.top2_show {
  animation: text_top2_show 0.5s ease forwards;
}
.bgColor_show {
  animation: li_bg_color_show 0.5s ease forwards;
}

.top1_none {
  animation: text_top1_none 0.5s ease forwards;
}
.top2_none {
  animation: text_top2_none 0.5s ease forwards;
}
.bgColor_none {
  animation: li_bg_color_none 0.5s ease forwards;
}

@keyframes li_bg_color_show {
  from {
    background-color: "";
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
@keyframes text_top1_show {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 3.5em;
    opacity: 1;
  }
}
@keyframes text_top2_show {
  from {
    top: 10em;
    opacity: 0;
  }
  to {
    top: 6.5em;
    opacity: 1;
  }
}

@keyframes li_bg_color_none {
  from {
    background-color: rgba(0, 0, 0, 0.5);
  }
  to {
    background-color: "";
  }
}
@keyframes text_top1_none {
  from {
    top: 4em;
    opacity: 0.5;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
@keyframes text_top2_none {
  from {
    top: 6.5em;
    opacity: 0.5;
  }
  to {
    top: 10em;
    opacity: 0;
  }
}
</style>
